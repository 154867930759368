app.directive( 'uAlbum', [ function () {
	return {
		restrict: 'E',
		scope: {
			startWithId: '@startWith'
		},
		templateUrl: 'ng/album.html',
		controller: 'AlbumController'
	};
} ] );

app.controller( 'SinglePaintingController', [ '$scope', function ( $scope ) {
} ] );

app.controller( 'AlbumController', [ '$scope', '$window', '$location', '$element', function ( $scope, $window, $location, $element ) {
	// $scope.loading = true;
	$scope.album = $window.album;
	$scope.numberOfImages = $scope.album.paintings.length;
	$scope.lastIndex = $scope.numberOfImages - 1;
	// $scope.firstIndex = findPositionForId( $scope.startWithId );
	// $scope.firstPreviousIndex = findPreviousIndex();
	// $scope.firstNextIndex = findNextIndex();
	$scope.currentIndex = findPositionForId( $scope.startWithId );
	// $scope.previousIndex = $scope.firstPreviousIndex;
	// $scope.nextIndex = $scope.firstNextIndex;
	$scope.itemWidth = $element.find( 'div' )[0].getBoundingClientRect().width;
	$scope.marginLeft = - $scope.itemWidth;
	// $scope.animate = false;
	// $scope.durationTime = 250;
	// $scope.ul = $element.find( 'ul' );

	angular.element( $window ).bind( 'resize', function() {
		$scope.itemWidth = $element.find( 'div' )[0].getBoundingClientRect().width;
		$scope.marginLeft = -$scope.itemWidth;
		$scope.$apply();
	});


	// $scope.ul = $element.find( 'ul' );
	// $scope.img = angular.element( $element.find( 'li' )[ 1 ] ).find( 'img' );

	//
	// var didMove = false;
	// //var didStart = false;
	// // var didEnd = false;
	// $swipe.bind($element, {
	// 	'start': function(coords) {
	// 		// if ( didStart ) {
	// 		// 	return;
	// 		// }
	// 		startX = coords.x;
	// 		$scope.marginLeft =  -$scope.itemWidth;
	// 		$scope.animate = false;
	// 		$scope.$apply();
	// 		didMove = false;
	// 		// didStart = true;
	// 		// didEnd = false;
	// 	},
	// 	'move': function(coords) {
	// 		var delta = coords.x - startX;
	// 		didMove = true;
	// 		$scope.marginLeft = delta - $scope.itemWidth;
	// 		$scope.animate = false;
	// 		$scope.$apply();
	// 		// $scope.ul.css( 'margin-left', $scope.marginLeft + 'px' );
	// 	},
	// 	'end': function(coords) {
	// 		if ( ! didMove ) {
	// 			return;
	// 		}
	// 		endX = coords.x;
	// 		$scope.animate = true;
	// 		if ( endX > startX ) {
	// 			$scope.marginLeft = 0;
	// 			$scope.$apply();
	// 			$timeout( function() {
	// 				// letztes li holen und vorne einfügen
	// 				var l = $element.find( 'li' ).eq( 2 );
	// 				$scope.ul.prepend( l );
	// 				$scope.animate = false;
	// 				$scope.marginLeft = -$scope.itemWidth;
	// 				$scope.backward( l );
	// 			}, $scope.durationTime )
	// 		}
	// 		else {
	// 			$scope.marginLeft = - 2 * $scope.itemWidth;
	// 			$scope.$apply();
	// 			$timeout( function() {
	// 				// letztes li holen und vorne einfügen
	// 				var l = $element.find( 'li' ).eq( 0 );
	// 				$scope.ul.append( l );
	// 				$scope.animate = false;
	// 				$scope.marginLeft = -$scope.itemWidth;
	// 				$scope.forward( l );
	// 			}, $scope.durationTime );
	//
	// 		}
	// 		// didEnd = true;
	//
	// 		// ...
	// 	},
	// 	'cancel': function(coords) {
	// 		// ...
	// 	}
	// });

	$scope.getMarginLeft = function() {
		return {
			'width': ( $scope.numberOfImages * $scope.itemWidth ) + 'px',
			'margin-left': ($scope.currentIndex * -$scope.itemWidth)+ 'px',
			// 'transition': 'margin-left ' + ( $scope.animate ? $scope.durationTime + 'ms' : '0s' )
		};
	};

	// $scope.paintings.push( $scope.album.paintings[ $scope.previousIndex ] );
	// $scope.paintings.push( $scope.album.paintings[ $scope.currentIndex ] );
	// $scope.paintings.push( $scope.album.paintings[ $scope.nextIndex ] );

	function findPositionForId( id ) {
		for ( i in $scope.album.paintings ) {
			var p = $scope.album.paintings[ i ];
			if ( p.slug == id ) {
				return parseInt( i, 10 );
			}
		}
		return 0;
	};
	// function findPreviousIndex() {
	// 	if ( ! $scope.currentIndex ) {
	// 		$scope.currentIndex = 0;
	// 	}
	//
	// 	if ( $scope.currentIndex > 0 ) {
	// 		return $scope.currentIndex - 1;
	// 	}
	//
	// 	if ( $scope.currentIndex === 0 ) {
	// 		return $scope.album.paintings.length - 1;
	// 	}
	// 	return 0;
	// };
	// function findNextIndex() {
	// 	if ( ! $scope.currentIndex ) {
	// 		$scope.currentIndex = 0;
	// 	}
	//
	// 	if ( $scope.currentIndex < $scope.lastIndex ) {
	// 		return $scope.currentIndex + 1;
	// 	}
	//
	// 	if ( $scope.currentIndex === $scope.lastIndex ) {
	// 		return 0;
	// 	}
	// 	return 0;
	// }
	function adjustUrl() {
		$location.url( '/' + $scope.album.paintings[ $scope.currentIndex ].slug );
	}

	// $scope.getImageForIndex = function( i ) {
	// 	return $scope.album.paintings[ i ].large;
	// }

	$scope.forward = function() {
		// if ( $scope.currentIndex < $scope.lastIndex ) {
		// 	$scope.currentIndex = $scope.currentIndex + 1;
		// }
		// else {
		// 	$scope.currentIndex = 0;
		// }

		$scope.currentIndex = Math.min( $scope.currentIndex + 1, $scope.lastIndex );
		// $scope.nextIndex = findNextIndex();
		// $scope.previousIndex = findFirstPreviousIndex();
		//$scope.marginLeft = - $scope.itemWidth;
		// angular.element( l ).find( 'img' ).attr( 'src', $scope.album.paintings[ $scope.nextIndex ].large );
		adjustUrl();
	}

	$scope.backward = function( l ) {
		$scope.currentIndex = Math.max( $scope.currentIndex - 1, 0 );
		//
		// if ( $scope.currentIndex > 0 ) {
		// 	$scope.currentIndex = $scope.currentIndex - 1;
		// }
		// else {
		// 	$scope.currentIndex = $scope.lastIndex;
		// }
		// $scope.previousIndex = findPreviousIndex();
		// $scope.nextIndex = findNextPosition();
		// $scope.marginLeft = - $scope.itemWidth;
		// angular.element( l ).find( 'img' ).attr( 'src', $scope.album.paintings[ $scope.previousIndex ].large );
		adjustUrl();
	}



} ] );
